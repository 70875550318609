import React, {Suspense, lazy} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import SuspenseLoader from '../components/loader';

import NotFound from '../components/screens/NotFound';
import {useAuthState} from '../contexts/AuthenticationContext';
import BaseLayout from '../layouts/baseLayout/BaseLayout';
import {DashboardLayout} from '../layouts/dashboardLayout/DashboardLayout';
import {routes} from './routes';
import type {RouteObject} from 'react-router-dom';


const Loader = (Component: React.ComponentType) => () =>
  <Suspense fallback={<SuspenseLoader />}>
    <Component />
  </Suspense>

const Login = Loader(lazy(() => import('../features/auth/login/Login')));

const Dashboard = Loader(lazy(() => import('../features/dashboard/Dashboard')));
const Contracts = Loader(lazy(() => import('../features/pages/Contracts')));
const Waybills = Loader(lazy(() => import('../features/waybills/Waybills')));
const Waybill = Loader(lazy(() => import('../features/waybills/features/Waybill')));
const WaybillAggregation = Loader(lazy(() => import('../features/waybills/aggregation/AggregateRoot')));
const WaybillCreate = Loader(lazy(() => import('../features/waybills/features/CreateWaybill')))
const MassBalance = Loader(lazy(() => import('../features/pages/MassBalance')));
const CompanyDetails = Loader(lazy(() => import('../features/company/CompanyDetails')));
const CompanyFiles = Loader(lazy(() => import('../features/company/files/CompanyFiles')));

const SourceDetails = Loader(lazy(() => import('../features/sources/Source')));
const Sources = Loader(lazy(() => import('../features/sources/Sources')));

const Destinations = Loader(lazy(() => import('../features/destination/Destinations')));
const WareHouses = Loader(lazy(() => import('../features/warehouse/Warehouses')));
const WareHouseDetails = Loader(lazy(() => import('../features/warehouse/Warehouse')));

const Profile = Loader(lazy(() => import('../features/pages/Profile')));
const Settings = Loader(lazy(() => import('../features/pages/Settings')));

const BaseLayoutView = () => {
  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  )
}

const DashboardView = () => {
  const {user, isLoading} = useAuthState()

  if (isLoading) return <></>
  if (!user) {
    return <Navigate to={routes.LOGIN} replace />
  }

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
}

const publicRoutes: RouteObject[] = [
  {
    element: <BaseLayoutView />,
    children: [
      {path: routes.ROOT, element: <Login />},
      {path: routes.LOGIN, element: <Login />},
    ]
  }
]

const privateRoutes: RouteObject[] = [
  {
    element: <DashboardView />,
    children: [
      {path: routes.APP.DASHBOARD, element: <Dashboard />},
      {path: routes.APP.CONTRACTS, element:  <Contracts />},
      {path: routes.APP.WAYBILL.ROOT, element:  <Waybills />},
      {path: routes.APP.WAYBILL.DETAILS, element:  <Waybill />},
      {path: routes.APP.WAYBILL.AGGREGATION, element:  <WaybillAggregation />},
      {path: routes.APP.WAYBILL.CREATE, element:  <WaybillCreate />},
      {path: routes.APP.MASS_BALANCE, element:  <MassBalance />},
      {path: routes.APP.FILES.LIST, element:  <CompanyFiles />},
      {path: routes.APP.COMPANY.ROOT, element:  <CompanyDetails />},
      {path: routes.APP.SOURCES.LIST, element:  <Sources />},
      {path: routes.APP.SOURCES.DETAILS, element:  <SourceDetails />},
      {path: routes.APP.DESTINATION.LIST, element:  <Destinations />},
      {path: routes.APP.WAREHOUSE.LIST, element:  <WareHouses />},
      {path: routes.APP.WAREHOUSE.DETAILS, element:  <WareHouseDetails />},
      {path: routes.APP.SETTINGS, element:  <Settings />},
      {path: routes.APP.PROFILE, element:  <Profile />},
    ]
  }
]

export const router: RouteObject[] = [
  ...publicRoutes,
  ...privateRoutes,
  {path: '*', element: <NotFound />}
]

export default router;
